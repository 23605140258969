<template>
  <div v-if="page" class="pb-3">
    <b-row>
      <b-col cols="12">
        <h1>{{ page.name }}</h1>
      </b-col>  
    </b-row>

    <div class="page" v-html="page.content" />
    <FileList v-if="!isLoading"
              entity-type="archive" 
              :entity-id="id"
              title="Bilagor" />
    <div class="text-right mt-5">
      <em>Senast ändrad
        {{ (page.edited || page.created) | datetime('yyyy-MM-dd HH:mm') }}
      </em>
    </div>

  </div>
</template>

<script>
import { get } from '@/helpers/api';
import FileList from '@/components/file/FileList';

export default {
  name: 'ArchiveGlobalPage',
  components: {
    FileList  
  },
  data() {
    return {
      isLoading: true,
      parentId: 0,
      page: null     
    };
  },
  computed: {
    id() {
      return typeof this.$route.params.id !== 'undefined'
        ? parseInt(this.$route.params.id)
        : 0;
    }
  },
  watch: {
    $route: 'GlobalPage'
  },
  async mounted() {
    await this.getPage();
  },
  methods: {
    update(){
       this.getPage();
    },
    async getPage() {
      this.isLoading = true;
      await get('Archive', `GetGlobalPage/${this.id}`)
        .then((x) => {
            this.page = x.data;
            this.parentId = x.data.parentId;
            this.isLoading = false;
        })
        .catch((x) => {
          this.$router.push({
            name: 'error',
            params: {
              statusCode: 404,
              statusText: 'Sidan kunde inte hittas.'
            }
          });
        });
    }
  }
};
</script>

<style>
.page img {
  max-width: 100%;
}
</style>
